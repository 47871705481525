var dataSourceQuickLinks = null;
$(function () {
  dataSourceQuickLinks = createQuicklinksDataSource();
  bindAutoCompleteEvents();
});
function bindAutoCompleteEvents() {
  // Instantiate the Typeahead UI
  $('.SearchBoxAutocomplete-typeaheadInput').typeahead({
    classNames: {
      input: 'SearchBoxAutocomplete-typeaheadInput',
      hint: 'SearchBoxAutocomplete-typeaheadHint',
      menu: 'SearchBoxAutocomplete-typeaheadMenu',
      dataset: 'SearchBoxAutocomplete-typeaheadDataset pageHeader-searchResultsContainer',
      suggestion: 'SearchBoxAutocomplete-typeaheadSuggestion',
      empty: 'SearchBoxAutocomplete-typeaheadEmpty',
      open: 'SearchBoxAutocomplete-typeaheadOpen',
      cursor: 'SearchBoxAutocomplete-typeaheadCursor',
      highlight: 'SearchBoxAutocomplete-typeaheadHighlight'
    },
    hint: false,
    highlight: true,
    minLength: 3,
    matcher: function matcher() {
      return true;
    } //server is responsible to match
  }, {
    limit: 8,
    source: dataSourceQuickLinks.ttAdapter(),
    displayKey: 'Title',
    templates: {
      suggestion: function suggestion(data) {
        var result = ["<div class='pageHeader-searchResultsItem'>", "<a class='pageHeader-searchResultsLink' href='" + data.Url + "'>", "<div><img src='" + data.ImageUrl + "' class='pageHeader-searchResultsImg' /></div>", "<div>", "<div class='pageHeader-searchResultsHeader'>" + data.Title + "</div>"];
        if (data.Description != null && data.Description.length > 0 && data.Title != data.Description) {
          result.push("<div class='pageHeader-searchResultsDesc'>" + data.Description + "</div>");
        }
        result.push("</div></a></div>");
        return result.join("\n");
      }
    }
  });
  $('.SearchBoxAutocomplete-typeaheadInput').on('typeahead:selected', function (evt, item) {
    if (item.Url) {
      triggerPageView(item.SearchUrl, item.Url);
      evt.preventDefault();
    }
  });
}
function createQuicklinksDataSource() {
  var dataSourceQuickLinks = new Bloodhound({
    datumTokenizer: function datumTokenizer(datum) {
      return Bloodhound.tokenizers.whitespace(datum.value);
    },
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: "/get-quicklinks?query=%QUERY&itemid=" + $("body").data("sc-item") + "&language=" + $('body').data("sc-language"),
      wildcard: '%QUERY',
      filter: function filter(data) {
        // Map the remote source JSON array to a JavaScript object array
        return $.map(data, function (item) {
          return {
            Title: item.Title,
            Url: replaceInvalidChars(item.Url),
            ImageUrl: item.ImageUrl,
            SearchUrl: item.SearchUrl,
            Description: item.Description
          };
        });
      }
    }
  });
  // Initialize the Bloodhound suggestion engine
  dataSourceQuickLinks.initialize();
  return dataSourceQuickLinks;
}
function replaceInvalidChars(value) {
  // escape characters to handle url with "'".
  var escaped = value.replace(/%20/g, " ").replace("'", '&#39;');
  return escaped;
}
function triggerPageView(searchPageUrl, redirectToUrl) {
  var contentGroups = getSearchPageContentGroups(searchPageUrl);
  gtag('config', gaCode, {
    'page_path': searchPageUrl,
    'content_group1': contentGroups[0],
    'content_group2': contentGroups[1],
    'content_group3': contentGroups[2]
  });
  if (typeof redirectToUrl !== "undefined" && typeof dataLayer !== "undefined") {
    dataLayer.push('event', 'search', {
      'dimension2': 'quicklink',
      'event_callback': loadPage(redirectToUrl)
    });
  }
}
function getSearchPageContentGroups(searchPageUrl) {
  var splittedSearchPageUrl = searchPageUrl.split("/");
  if (splittedSearchPageUrl.length < 3) return ["", "", ""];
  var contentGroup1 = splittedSearchPageUrl[1] + "/" + splittedSearchPageUrl[2];
  var contentGroup2 = "search page";
  var contentGroup3 = "search";
  return [contentGroup1, contentGroup2, contentGroup3];
}
function loadPage(redirectToUrl) {
  window.location.assign(redirectToUrl);
}